import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "si-field radio-input-group flex-col" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["name", "id", "value", "checked"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Field, {
      name: _ctx.name,
      rules: _ctx.validate
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "radio-option",
            key: i
          }, [
            _createElementVNode("div", {
              class: "flex-row flex-gap-8",
              onClick: ($event: any) => (_ctx.onChange(option.value))
            }, [
              _createElementVNode("input", {
                type: "radio",
                name: _ctx.name,
                id: option.label,
                value: option.value,
                checked: _ctx.modelValue == option.value
              }, null, 8, _hoisted_3),
              _createElementVNode("label", {
                for: _ctx.name,
                class: "radio-label"
              }, _toDisplayString(option.label), 9, _hoisted_4)
            ], 8, _hoisted_2)
          ]))
        }), 128)),
        (!_ctx.isValid)
          ? (_openBlock(), _createBlock(_component_ErrorMessage, {
              key: 0,
              name: _ctx.name,
              class: "si-field-invalid",
              as: 'span'
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.error), 1)
              ]),
              _: 1
            }, 8, ["name"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["name", "rules"])
  ]))
}