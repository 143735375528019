
import { OrderPayment } from '@/common/models/OrderPayment.model';
import { Options, Vue } from 'vue-class-component';
import { InjectReactive } from 'vue-property-decorator';
import ProgressButton from '@/common/components/progress-button.component.vue';
import ActiveOrder from '@/modules/order/common/classes/ActiveOrder.class';
import { OrderMerchandiseGiftCard } from '@/common/models/OrderMerchandiseGiftCard.model';
@Options({
	name: 'GiftCardPaymentMethod',
	components: { ProgressButton },
})
export default class GiftCardPaymentMethod extends Vue {
	@InjectReactive() orderPayment!: OrderPayment;
	@InjectReactive() activeOrder!: ActiveOrder;

	giftcard: OrderMerchandiseGiftCard | null = null;

	validating = false;

	get gcNumber(): string {
		return this.orderPayment.GiftCardCode ?? '';
	}

	set gcNumber(v: string) {
		this.orderPayment.GiftCardCode = v;
	}

	get remainingBalance() {
		if (this.giftcard) return this.giftcard?.Balance - this.orderPayment.PaymentAmount;
		return 0;
	}

	get paymentAmount() {
		return this.orderPayment.PaymentAmount;
	}

	set paymentAmount(v: number | string) {
		const amt = Number(v);
		if (isNaN(Number(amt)) || v < 0) return;
		this.orderPayment.PaymentAmount = amt > this.orderPayment.Balance ? this.orderPayment.Balance : amt; // max is order balance
		this.$emit('canSubmit', this.giftcard && amt <= this.giftcard.Balance && amt > 0);
	}

	async validateGiftCard() {
		this.validating = true;
		await this.activeOrder
			.getGiftCard(this.gcNumber)
			.then((gc) => {
				console.log(gc);
				if (gc) {
					console.log('GiftCard]', gc);
					this.giftcard = gc;
					this.paymentAmount = gc.Balance > this.orderPayment.Balance ? this.orderPayment.Balance : gc.Balance; // set to order balance
				}
			})
			.finally(() => (this.validating = false));
	}

	async redeemGiftCard() {
		this.validating = true;
		await this.activeOrder.redeemGiftCard(this.gcNumber).finally(() => (this.validating = false));
	}
}
