
import { Options, Vue } from 'vue-class-component';
import InfoBox from './info-box.component.vue';
import TextInput from '@/common/components/form-ui/text-input.component.vue';
import Checkbox from '@/common/components/form-ui/checkbox.component.vue';
import SelectInput from '@/common/components/form-ui/select-input.component.vue';
import { Form } from 'vee-validate';
import { InjectReactive, Prop } from 'vue-property-decorator';
import ActiveOrder from '@/modules/order/common/classes/ActiveOrder.class';
import { isRequired, validEmail } from '@/common/utilities/Validators.utility';
import PhoneNumberInput from '@/modules/order/details/components/phone-number-input.component..vue';
import EmailOptIn from '@/common/components/form-ui/email-opt-in.vue';
type PhoneNumber = {
	country: number | null;
	area: string | null;
	subscriber: string | null;
};

@Options({
	name: 'ContactInfoBox',
	components: { InfoBox, TextInput, Checkbox, SelectInput, Form, PhoneNumberInput, EmailOptIn },
	emits: ['onNext', 'onEdit'],
})
export default class ContactInfoBox extends Vue {
	@Prop({ default: true }) isActive!: boolean;
	@InjectReactive() activeOrder!: ActiveOrder;

	useTicketing!: boolean;
	textTickets = true;

	isRequired = isRequired;
	validEmail = validEmail;
	emailFailed = false;

	loading = false;
	get customer() {
		return this.activeOrder.customerInfo;
	}

	get customerPhone(): PhoneNumber {
		return {
			country: this.customer.PhoneCountryKey,
			area: this.customer.PhoneAreaCode,
			subscriber: this.customer.PhoneSubscriberNumber,
		};
	}
	set customerPhone(newValue: PhoneNumber) {
		if (this.customer) {
			this.customer.PhoneCountryKey = newValue.country ?? -1;
			this.customer.PhoneAreaCode = newValue.area ?? '';
			this.customer.PhoneSubscriberNumber = newValue.subscriber ?? '';
		}
	}

	get phoneNumStr() {
		return `(${this.customer.PhoneAreaCode}) ${this.customer.PhoneSubscriberNumber.slice(0, 3)}-${this.customer.PhoneSubscriberNumber.slice(3)}`;
	}

	created() {
		this.useTicketing = this.activeOrder.getStaticProperty('UseTicketing') ?? false;
	}

	handleValidPhone(phone: PhoneNumber) {
		this.customer.PhoneCountryKey = phone.country ?? 0;
		this.customer.PhoneAreaCode = phone.area ?? '';
		this.customer.PhoneSubscriberNumber = phone.subscriber ?? '';
	}
	hasEmailFailed() {
		const val = !this.emailFailed;
		this.emailFailed = false;
		return val;
	}
	onSubmit(v: unknown) {
		this.emailFailed = false;
		this.customer.ApprovedTextTickets = this.textTickets;
		// if (!this.activeOrder.getStaticProperty('RequiresPasscodeToCheckout')) {
		this.loading = true;
		console.log('HERE');
		this.activeOrder
			.saveCustomerToOrder()
			.then((res) => {
				if (res.Order) {
					this.$emit('onNext');
				} else if (res.ModelErrors) {
					this.emailFailed =
						res.ModelErrors?.map((e: { Key: string; Value: string[] }) => e.Value)
							.flat()
							.includes('Invalid email address. Please double check your spelling') ?? false;
				}
			})
			.finally(() => {
				this.loading = false;
			});
		// } else {
		// 	console.log('Requires passcode to checkout!?');
		// }
	}
}
