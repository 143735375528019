import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "alertModal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.request)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.request.name), {
          requestData: _ctx.request.data,
          onCloseRequest: _cache[0] || (_cache[0] = ($event: any) => (_ctx.request = null))
        }, null, 40, ["requestData"]))
      ]))
    : _createCommentVNode("", true)
}