import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "si-field flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Field, {
      as: "textarea",
      name: _ctx.name,
      rules: _ctx.validateField,
      class: "cf-textarea si-input",
      placeholder: _ctx.placeholder,
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
      rows: "6",
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hasTouched = true))
    }, null, 8, ["name", "rules", "placeholder", "value"]),
    (_ctx.invalid)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          name: _ctx.name,
          class: "si-field-invalid",
          as: 'span'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.error), 1)
          ]),
          _: 1
        }, 8, ["name"]))
      : _createCommentVNode("", true)
  ]))
}