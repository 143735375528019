import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "alert-header flex-col flex-center alert-content" }
const _hoisted_2 = { class: "alert-title" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_si_icon = _resolveComponent("si-icon")!
  const _component_OnClickOutside = _resolveComponent("OnClickOutside")!

  return (_openBlock(), _createBlock(_component_OnClickOutside, { onTrigger: _ctx.onClose }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        id: "errorAlert",
        class: "flex-col flex-center simple-alert",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_si_icon, {
            name: 'error',
            class: "alert-icon",
            width: 2
          }),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
          _createElementVNode("p", {
            class: "si-subtext",
            innerHTML: _ctx.subtext
          }, null, 8, _hoisted_3)
        ])
      ])
    ]),
    _: 1
  }, 8, ["onTrigger"]))
}