import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "si-chip",
    style: _normalizeStyle(`background: ${_ctx.color}`),
    title: _ctx.title
  }, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.handleClose()), ["stop"])),
      innerHTML: _ctx.closeBtn
    }, null, 8, _hoisted_2),
    _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
  ], 12, _hoisted_1))
}