import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "info-box flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "title"),
    (_ctx.isActive)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : _renderSlot(_ctx.$slots, "summary", { key: 1 }),
    (!_ctx.isActive && _ctx.canEdit)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          class: "ib-edit-btn highlight-text naked fw-medium",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onEdit')))
        }, "Edit"))
      : _createCommentVNode("", true)
  ]))
}