enum PaymentType {
	QUANTITY = 1,
	AMOUNT = 2,
	PERCENT = 3,
}

enum DiscountType {
	PREDEFINED = 4,
	PERCENTAGE = 1,
	AMOUNT = 2,
}

enum RefundMethodType {
	CARD_ON_FILE = 1,
	CASH = 2,
	TERMINAL = 3,
	VOUCHER = 4,
	CHECK = 5,
	DEBIT_EXTERNAL = 6,
	CREDIT_EXTERNAL = 7,
	GIFT_CARD_EXTERNAL = 8,
	SQUARE_EXTERNAL = 9,
	HOTEL_GUEST_EXTERNAL = 11,
	INTERNAL_DEPARTMENT_EXTERNAL = 12,
}

enum ProcessingType {
	NOW = 1,
	LATER_WITH_CARD = 3,
	LATER_WITHOUT_CARD = 4,
}

enum CardType {
	NONE = 0,
	VISA = 1,
	MASTERCARD = 2,
	AMEX = 3,
	DISCOVER = 4,
	DINERS = 5,
	JCB = 7,
	CHINA_UNION_PAY = 18,
	SQUARE_GIFT_CARD = 19,
	SQUARE_CAPITAL_CARD = 20,
	INTERAC = 21,
	EFTPOS = 22,
	FELICA = 23,
}

export { PaymentType, CardType, ProcessingType, RefundMethodType, DiscountType };
