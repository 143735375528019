
import { PackageViewMode } from '@/common/enums/PackageViewMode.enum';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import { clientModule } from '@/modules/client/client.vuex-module';
import { ProvideReactive } from 'vue-property-decorator';

import AdditionalInfoBox from '@/modules/order/details/components/info-boxes/additional-info-box.component.vue';
import ContactInfoBox from '@/modules/order/details/components/info-boxes/contact-info-box.component.vue';
import OrderSummaryInfoBox from '@/modules/order/details/components/summary/order-summary.component.vue';
import PrePaymentInfoBox from '@/modules/order/details/components/info-boxes/pre-payment-info-box.component.vue';

import AlertModal from '@/common/components/alert-modal.component.vue';

import PromoCode from '../components/promo-code.component.vue';

import { OrderPayment } from '@/common/models/OrderPayment.model';
import ActiveOrder from '@/modules/order/common/classes/ActiveOrder.class';
import LoaderDisplay from '@/common/components/loader-display.component.vue';
import { PaymentMethod } from '@/common/enums/PaymentMethod.enum';

enum BoxType {
	CONTACT = 'contact',
	ADDINFO = 'addInfo',
	PAYMENTS = 'payments',
	LOADING = 'loading',
	NONE = 'none',
}

type PaymentResult = {
	success: boolean;
	squareJSON: string | undefined;
	amount: number | undefined;
	error: string | undefined;
};

@Options({
	name: 'CheckoutView',
	components: { ContactInfoBox, AdditionalInfoBox, PrePaymentInfoBox, OrderSummaryInfoBox, PromoCode, LoaderDisplay, AlertModal },
})
export default class CheckoutView extends Vue {
	@Prop({ default: '0' }) clientLocationKey!: string;
	@Prop({ default: '0' }) type!: PackageViewMode;
	@Prop({ default: '0' }) typekey!: string;
	BoxType = BoxType;
	activeBox: BoxType = BoxType.CONTACT;
	paymentJSON: string | null = null;
	private localError: string | null = null;
	created() {
		orderModule.saveAll().then(() => {
			console.log('Setting edit item');
			//open first item after the order was saved
			let orderItem = this.activeOrder?.items.filter((i) => i.IsNew)[0];
			if (orderItem) {
				orderModule.editItem(orderItem);
			} else {
				console.log('Order does not have any new items');
			}
			this.activeBox = this.validContact ? (this.validAdditional ? BoxType.PAYMENTS : BoxType.ADDINFO) : BoxType.CONTACT;
		});
	}
	get validContact() {
		return orderModule._activeOrder?.contactInfoValid ?? false;
	}
	get validAdditional() {
		return orderModule._activeOrder?.additionalInfoValid ?? false;
	}
	get order() {
		return orderModule;
	}
	get isLoading() {
		return orderModule.isLoading || !this.activeOrder;
	}
	get error(): string | null {
		return this.activeOrder?.error ?? this.localError;
	}
	@ProvideReactive() get orderPayment(): OrderPayment | null {
		return this.activeOrder?.paymentDue ?? null;
	}
	@ProvideReactive() get activeOrder(): ActiveOrder | null {
		return orderModule._activeOrder ?? null;
	}
	@ProvideReactive() get desktopView(): boolean {
		return clientModule.desktopView;
	}
	handleNext(): void {
		if (this.activeBox == BoxType.CONTACT) {
			this.activeBox = BoxType.ADDINFO;
		} else if (this.activeBox == BoxType.ADDINFO) this.activeBox = BoxType.PAYMENTS;
	}
	handleEdit(box: BoxType): void {
		this.activeBox = box;
	}
	handlePayment(): void {
		if (!this.activeOrder?.paymentDue && !this.activeOrder?.paid) {
			console.log('No Payment Generated!', this.activeOrder?.paymentDue, this.activeOrder?.paid);
			return;
		}
		if (this.activeOrder.paid) {
			this.activeOrder.savePayment({}).then(() => {
				console.log(this.activeOrder);
				if (!this.activeOrder?.isNew) {
					this.$router.push(`/portal/confirmation`);
				} else {
					console.log('Order is still new!');
				}
			});
			return;
		}
		this.localError = null;
		if (this.paymentJSON && this.activeOrder && this.activeOrder.paymentDue) {
			this.activeBox = BoxType.LOADING;
			this.activeOrder.paymentDue.PaymentMethodTypeKey == PaymentMethod.CARD;
			this.activeOrder?.savePayment({ Response: this.paymentJSON }).then(() => {
				console.log(this.activeOrder);
				if (!this.activeOrder?.isNew) {
					this.$router.push(`/portal/confirmation`);
				} else {
					this.activeBox = BoxType.PAYMENTS;
					this.localError = 'Retry failed, please contact a staff member for help';
					console.log('Order is still new!');
				}
			});
			return;
		}
		let wk = (window as any).webkit;
		(window as any).onPaymentCallback = (result: PaymentResult) => {
			if (result.success) {
				this.activeOrder?.savePayment({ Response: result.squareJSON }).then(() => {
					console.log(this.activeOrder);
					if (!this.activeOrder?.isNew) {
						this.$router.push(`/portal/confirmation`);
					} else {
						this.activeBox = BoxType.PAYMENTS;
						this.localError = orderModule._activeOrder?.error ?? 'Unable to save booking, please try again';
						this.paymentJSON = JSON.stringify(result.squareJSON);
						console.log('Order is still new!');
					}
				});
			} else {
				this.activeBox = BoxType.PAYMENTS;
				this.localError = result.error ?? 'Unable to process card';
			}
		};
		this.activeBox = BoxType.LOADING;
		orderModule.openActivity?.checkTimes().then((valid: boolean) => {
			if (valid) {
				console.log('time is valid!');
				if (wk && wk.messageHandlers && wk.messageHandlers.onPayment) {
					wk.messageHandlers.onPayment.postMessage({
						amount: this.activeOrder?.paymentDue?.Balance ?? 0,
					});
				}
			} else {
				this.activeBox = BoxType.PAYMENTS;
				this.localError = 'This time is no longer available, please go back and select another.';
			}
		});
	}
}
