import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "select-input" }
const _hoisted_2 = ["value", "tabindex"]
const _hoisted_3 = {
  value: -1,
  selected: "",
  disabled: ""
}
const _hoisted_4 = ["value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.name ? 'Field' : 'div'), {
    name: _ctx.name,
    class: "si-field",
    rules: _ctx.validateField
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("select", {
          class: "si-input",
          value: _ctx.value,
          onChange: _cache[0] || (_cache[0] = ($event: any) => {
					_ctx.value = ($event.target as HTMLSelectElement).value;
					_ctx.$emit('onChange', ($event.target as HTMLSelectElement).value);
				}),
          tabindex: _ctx.tabIndex
        }, [
          _createElementVNode("option", _hoisted_3, _toDisplayString(_ctx.placeholder), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (opt, i) => {
            return (_openBlock(), _createElementBlock("option", {
              key: i,
              value: opt.value,
              disabled: opt.disabled
            }, _toDisplayString(opt.label), 9, _hoisted_4))
          }), 128))
        ], 40, _hoisted_2)
      ]),
      (_ctx.name)
        ? (_openBlock(), _createBlock(_component_ErrorMessage, {
            key: 0,
            name: _ctx.name,
            class: "si-field-invalid",
            as: 'span'
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.error), 1)
            ]),
            _: 1
          }, 8, ["name"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["name", "rules"]))
}