
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Field, ErrorMessage } from 'vee-validate';

@Options({
	name: 'SelectInput',
	components: { Field, ErrorMessage },
	emits: ['update:modelValue', 'onChange'],
})
export default class SelectInput extends Vue {
	@Prop() options!: { label: string; value: unknown; disabled?: boolean }[];
	@Prop() modelValue!: string | number | null;
	@Prop({ default: 'Select' }) placeholder!: string;
	@Prop({ default: [] }) validators!: ((v: any) => boolean)[];
	@Prop({ default: 'This field is invalid' }) error!: string;
	@Prop({ default: '' }) name!: string;
	@Prop({ default: null }) tabIndex!: number;

	invalid = false;

	mounted() {
		this.invalid = false;
	}

	get value() {
		return this.modelValue ?? -1;
	}

	set value(v: number | string) {
		this.$emit('update:modelValue', v);
	}

	validateField() {
		const valid = this.validators.every((v) => v(this.modelValue));
		this.invalid = !valid;
		return valid;
	}
}
