import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "si-field flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Field, {
      type: _ctx.type,
      class: "si-input",
      placeholder: _ctx.placeholder,
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
      name: _ctx.name,
      rules: _ctx.validateField,
      autocomplete: "off",
      spellcheck: "false"
    }, null, 8, ["type", "placeholder", "value", "name", "rules"]),
    _createVNode(_component_ErrorMessage, {
      name: _ctx.name,
      class: "si-invalid",
      as: 'span'
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.error), 1)
      ]),
      _: 1
    }, 8, ["name"])
  ]))
}