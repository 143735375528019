
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Field, ErrorMessage } from 'vee-validate';

@Options({
	name: 'TextInput',
	components: { Field, ErrorMessage },
	emits: ['update:modelValue', 'isValid'],
})
export default class TextInput extends Vue {
	@Prop() name!: string;
	@Prop() modelValue!: string;
	@Prop() placeholder!: string;
	@Prop({ default: 'This field is invalid' }) error!: string;
	@Prop({ default: [] }) validators!: ((v: any) => boolean)[];

	hasTouched = false;

	invalid = false;

	mounted() {
		this.invalid = false;
	}

	validateField(val: unknown): boolean {
		const valid = this.validators.every((v) => v(val));
		this.invalid = !valid;
		this.$emit('isValid', valid);
		return valid;
	}
}
