import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "si-field select-input country" }
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  key: 0,
  value: -1,
  selected: ""
}
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("select", {
      class: "si-input",
      value: _ctx.modelValue,
      style: _normalizeStyle('background-image: url(' + _ctx.countryFlag + ')'),
      onChange: _cache[0] || (_cache[0] = ($event: any) => {_ctx.update(($event.target as HTMLSelectElement).value);})
    }, [
      (_ctx.sayPlease)
        ? (_openBlock(), _createElementBlock("option", _hoisted_3, _toDisplayString(_ctx.defaultOptionLabel), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (opt, i) => {
        return (_openBlock(), _createElementBlock("option", {
          key: i,
          value: opt.value
        }, _toDisplayString(opt.label), 9, _hoisted_4))
      }), 128))
    ], 44, _hoisted_2)
  ]))
}