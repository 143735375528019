import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "opt-in" }
const _hoisted_2 = { class: "bool-container" }
const _hoisted_3 = { class: "flex fill gap v-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkbox = _resolveComponent("checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "cb flex v-center center",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)), ["prevent","stop"]))
        }, [
          _createVNode(_component_checkbox, {
            checked: _ctx.activeOrder?.customerInfo.EmailOptIn
          }, null, 8, ["checked"])
        ]),
        _createElementVNode("div", {
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)), ["prevent"]))
        }, "Do you want to join our mailing list?")
      ])
    ])
  ]))
}