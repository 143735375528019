import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "payment-method-wrapper" }
const _hoisted_2 = {
  class: "payment-pad selected-payment flex-col",
  id: "gcPayment"
}
const _hoisted_3 = { class: "flex-col" }
const _hoisted_4 = {
  class: "flex-row flex-justify-sb",
  id: "gcValidateField"
}
const _hoisted_5 = { class: "payment-field num-field gc-field" }
const _hoisted_6 = {
  key: 0,
  class: "gc-sub"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_progress_button = _resolveComponent("progress-button")!
  const _component_currency = _resolveComponent("currency")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "si-input",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.gcNumber) = $event)),
              placeholder: "Gift card number"
            }, null, 512), [
              [_vModelText, _ctx.gcNumber]
            ])
          ]),
          _createVNode(_component_progress_button, {
            showLoader: _ctx.validating,
            disabled: _ctx.gcNumber.length == 0,
            onClick: _ctx.redeemGiftCard
          }, {
            default: _withCtx(() => [
              _createTextVNode("Redeem")
            ]),
            _: 1
          }, 8, ["showLoader", "disabled", "onClick"])
        ]),
        (_ctx.giftcard)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
              _createVNode(_component_currency, {
                amount: _ctx.giftcard.Balance
              }, null, 8, ["amount"]),
              _createTextVNode(" on card")
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}