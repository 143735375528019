import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-col phone-input" }
const _hoisted_2 = { class: "flex-row si-row tele-info" }
const _hoisted_3 = { class: "si-field" }
const _hoisted_4 = { class: "si-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_country_select_input = _resolveComponent("country-select-input")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_country_select_input, {
        modelValue: _ctx.localPhone.country,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localPhone.country) = $event))
      }, null, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Field, {
          type: 'tel',
          class: "si-input",
          modelValue: _ctx.localPhone.area,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localPhone.area) = $event)),
          rules: _ctx.isRequired,
          autocomplete: "off",
          spellcheck: "false",
          name: 'area',
          onInput: _ctx.onAreaInput
        }, null, 8, ["modelValue", "rules", "onInput"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Field, {
          type: 'tel',
          class: "si-input",
          modelValue: _ctx.localPhone.subscriber,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localPhone.subscriber) = $event)),
          rules: [_ctx.isRequired, _ctx.validPhoneNumber],
          autocomplete: "off",
          spellcheck: "false",
          name: 'subscriber',
          id: "subNum"
        }, null, 8, ["modelValue", "rules"])
      ])
    ]),
    _createVNode(_component_ErrorMessage, {
      name: 'subscriber',
      class: "si-invalid",
      as: 'span'
    }, {
      default: _withCtx(() => [
        _createTextVNode("A valid phone number is required")
      ]),
      _: 1
    })
  ]))
}