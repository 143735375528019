
import { Options, Vue } from 'vue-class-component';
import { OnClickOutside } from '@vueuse/components';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'ErrorAlert',
	components: { OnClickOutside },
	emits: ['closeRequest'],
})
export default class ErrorAlert extends Vue {
	@Prop() requestData!: {
		title: string;
		subtext: string;
		callstack?: string | null;
		onClose?: () => void;
	};

	get title() {
		return this.requestData?.title ?? 'Oops... Something Went Wrong';
	}

	get subtext() {
		return this.requestData?.subtext ?? 'Please try your request again';
	}

	onClose() {
		this.$emit('closeRequest');
		if (this.requestData.onClose) this.requestData.onClose();
	}
}
