import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "promo-fields" }
const _hoisted_2 = { class: "promos-input" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 1,
  class: "applied-promos"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_count_badge = _resolveComponent("count-badge")!
  const _component_small_loader = _resolveComponent("small-loader")!
  const _component_chip = _resolveComponent("chip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["cart-promo flex-col flex-justify-start", { 'exp-promo': _ctx.expandPromo }])
  }, [
    _createElementVNode("button", {
      class: "naked highlight-text fw-bold flex-row",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.togglePromoField()))
    }, [
      _createTextVNode(" Apply promo/discount codes "),
      (_ctx.appliedPromos.length)
        ? (_openBlock(), _createBlock(_component_count_badge, {
            key: 0,
            count: _ctx.appliedPromos.length
          }, null, 8, ["count"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "order-promo-input",
          placeholder: "Enter code",
          id: "promoInput",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.promoCode) = $event)),
          ref: "promoInput",
          onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onFocus())),
          onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onBlur())),
          onKeydown: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.applyPromo()), ["enter"]))
        }, null, 544), [
          [_vModelText, _ctx.promoCode]
        ]),
        _createElementVNode("button", {
          class: "btn-highlight fw-medium",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.applyPromo())),
          disabled: !_ctx.promoCode.length || !_ctx.activeOrder
        }, [
          (_ctx.applyingPromo)
            ? (_openBlock(), _createBlock(_component_small_loader, { key: 0 }))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, "Apply"))
        ], 8, _hoisted_3)
      ])
    ]),
    (_ctx.appliedStatus.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["promo-status flex-col flex-align-center", _ctx.promoCode.length ? 'promo-failure' : 'promo-success'])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appliedStatus, (status, i) => {
            return (_openBlock(), _createElementBlock("div", { key: i }, _toDisplayString(status), 1))
          }), 128)),
          _createElementVNode("button", {
            class: "naked",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.appliedStatus = []))
          }, "OK")
        ], 2))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appliedPromos, (p) => {
            return (_openBlock(), _createBlock(_component_chip, {
              key: p.PromoCode,
              label: p.PromoCode,
              onOnClose: ($event: any) => (_ctx.onDelete(p)),
              animateOnClose: true
            }, null, 8, ["label", "onOnClose"]))
          }), 128))
        ]))
  ], 2))
}