import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "radio-btn-grp flex-col" }
const _hoisted_2 = ["onClick", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
      return (_openBlock(), _createElementBlock("button", {
        key: i,
        class: _normalizeClass(["flex-row fw-medium", { 'opt-selected': option.value == _ctx.modelValue }]),
        onClick: ($event: any) => (_ctx.$emit('update:modelValue', option.value)),
        innerHTML: option.label
      }, null, 10, _hoisted_2))
    }), 128))
  ]))
}