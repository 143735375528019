import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "summary-item" }
const _hoisted_2 = {
  key: 0,
  class: "summary-item-row item-pkg"
}
const _hoisted_3 = { class: "fw-heavy" }
const _hoisted_4 = { class: "fw-heavy" }
const _hoisted_5 = { class: "summary-item-row" }
const _hoisted_6 = { class: "fw-heavy" }
const _hoisted_7 = { class: "fw-heavy" }
const _hoisted_8 = ["title"]
const _hoisted_9 = { class: "fw-medium flex-row" }
const _hoisted_10 = {
  key: 0,
  class: "status-dot"
}
const _hoisted_11 = { class: "summary-item-row" }
const _hoisted_12 = { class: "summary-item-row" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_currency = _resolveComponent("currency")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.item.PackageKey)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.item.Name), 1),
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_currency, { amount: _ctx.itemSubtotal }, null, 8, ["amount"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activityData, (activity) => {
      return (_openBlock(), _createElementBlock("div", {
        key: activity.OrderEntityKey,
        class: "item-activity"
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(activity.name), 1),
          _createElementVNode("span", _hoisted_7, [
            _createVNode(_component_currency, {
              amount: activity.subtotal
            }, null, 8, ["amount"])
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activity.selectedTimes, (time, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            class: "activity-timeslot"
          }, [
            _createElementVNode("div", {
              class: "summary-item-row item-time",
              title: time.status
            }, [
              _createElementVNode("div", _hoisted_9, [
                (_ctx.isSaved)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(time.timeStr), 1)
              ])
            ], 8, _hoisted_8),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(time.rates, (rate, j) => {
              return (_openBlock(), _createElementBlock("div", {
                key: j,
                class: "activity-rates"
              }, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(rate.name) + " ", 1),
                    _createVNode(_component_currency, {
                      amount: rate.price
                    }, null, 8, ["amount"]),
                    _createTextVNode(" x " + _toDisplayString(rate.quantity), 1)
                  ]),
                  _createElementVNode("span", null, [
                    _createVNode(_component_currency, {
                      amount: rate.subtotal
                    }, null, 8, ["amount"])
                  ])
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(rate.discounts, (discount, k) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["summary-item-row item-rate-discount", { hidden: discount.subtotal == 0 }]),
                    key: k
                  }, [
                    _createElementVNode("span", null, _toDisplayString(discount.name), 1),
                    _createElementVNode("span", null, [
                      _createTextVNode("-"),
                      _createVNode(_component_currency, {
                        amount: discount.subtotal
                      }, null, 8, ["amount"])
                    ])
                  ], 2))
                }), 128))
              ]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(time.addons, (addon, l) => {
              return (_openBlock(), _createElementBlock("div", {
                key: l,
                class: "activity-rates"
              }, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(addon.name) + " ", 1),
                    (!addon.isTip && addon.variants.length == 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                          _createVNode(_component_currency, {
                            amount: addon.price
                          }, null, 8, ["amount"]),
                          _createTextVNode(" x " + _toDisplayString(addon.quantity), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  (addon.variants.length == 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                        _createVNode(_component_currency, {
                          amount: addon.subtotal
                        }, null, 8, ["amount"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(addon.variants, (v, z) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: z,
                    class: "summary-item-row"
                  }, [
                    _createElementVNode("span", null, [
                      _createTextVNode(" - " + _toDisplayString(v.name) + " ", 1),
                      (!v.isTip)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                            _createVNode(_component_currency, {
                              amount: v.price
                            }, null, 8, ["amount"]),
                            _createTextVNode(" x " + _toDisplayString(v.quantity), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("span", null, [
                      _createVNode(_component_currency, {
                        amount: v.subtotal
                      }, null, 8, ["amount"])
                    ])
                  ]))
                }), 128))
              ]))
            }), 128))
          ]))
        }), 128))
      ]))
    }), 128))
  ]))
}