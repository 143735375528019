
import { Options, Vue } from 'vue-class-component';
import { InjectReactive, Prop } from 'vue-property-decorator';
import InfoBox from './info-box.component.vue';
import TextInput from '@/common/components/form-ui/text-input.component.vue';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { clientModule } from '@/modules/client/client.vuex-module';
import SelectInput from '@/common/components/form-ui/select-input.component.vue';
import ActiveOrder from '@/modules/order/common/classes/ActiveOrder.class';
import CustomField from '@/common/components/form-ui/custom-field.component.vue';
import RequirementsToParticipate from '@/common/components/form-ui/requirements-to-participate.component.vue';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import { getCustomFieldResponseString } from '@/common/utilities/customFieldResponse.utility';
import { ClientLocationCustomField } from '@/common/models/ClientLocationCustomField.model';

@Options({
	name: 'AdditionalInfoBox',
	components: { InfoBox, TextInput, SelectInput, Form, CustomField, RequirementsToParticipate, Field, ErrorMessage },
	emits: ['onNext', 'onEdit'],
})
export default class AdditionalInfoBox extends Vue {
	@Prop({ default: true }) isActive!: boolean;
	@Prop({ default: true }) canEdit!: boolean;
	loading = false;

	get heardAboutOptions() {
		const options: { label: string; value: string | number | null }[] = [];
		if (!clientModule.location) {
			return options;
		}
		options.push(
			...clientModule.location.HeardAboutUs.map((h) => {
				return {
					label: h.Description,
					value: h.ClientLocationHeardAboutUsKey as number,
				};
			}),
		);
		return options;
	}
	get activeOrder() {
		return orderModule._activeOrder!;
	}
	get customer() {
		return this.activeOrder.customerInfo;
	}

	get customFields(): ClientLocationCustomField[] {
		return this.activeOrder.customFields;
	}

	get summaryFields() {
		const fields: { field: string; response: string }[] = [];
		if (this.customer.ClientLocationHeardAboutUsKey != null) {
			const responseKey = this.customer.ClientLocationHeardAboutUsKey;
			if (responseKey == 0) fields.push({ field: 'Heard About Us', response: this.customer.HeardAboutUsOther });
			else fields.push({ field: 'Heard About Us', response: this.heardAboutOptions.find((opt) => opt.value == responseKey)?.label ?? '' });
		}
		if (this.customer.Agreed) {
			fields.push({ field: 'Requirements to participate', response: 'Agreed' });
		}

		this.customFields.forEach((field) => {
			let response = getCustomFieldResponseString(field);
			console.log('>>>>Cf', field.CustomFieldName, field, response);
			if (response) fields.push({ field: field.CustomFieldName, response: response as string });
		});

		return fields;
	}
	get canSkipSection() {
		//Hard coding false because they always need to complete requirements to participate
		return false;
		// return !this.customFields.find((cf) => cf.IsRequired);
	}
	isAgreed() {
		return this.activeOrder.agreed;
	}
	heardAboutUsValid() {
		if (this.heardAboutOptions.length == 0) return true;
		if (this.customer.ClientLocationHeardAboutUsKey != null) return this.customer.ClientLocationHeardAboutUsKey >= 0;
		return false;
	}
	heardAboutUsOtherValid() {
		return this.customer.ClientLocationHeardAboutUsKey != 0 || this.customer.HeardAboutUsOther;
	}
	onSubmit() {
		this.loading = true;
		this.activeOrder
			.saveCustomerToOrder()
			.then((res) => {
				if (res.Order) {
					this.$emit('onNext');
				}
			})
			.finally(() => {
				this.loading = false;
			});
	}
}
