export enum PaymentMethod {
	OTHER = 0,
	ECOMMERCE = 1,
	CASH = 2,
	CHECK = 3,
	VOUCHER = 4,
	CARD = 5,
	DEBIT_EXTERNAL = 7,
	CREDIT_EXTERNAL = 8,
	GIFT_CARD_EXTERNAL = 9,
	SQUARE_EXTERNAL = 10,
	GIFT_CARD = 11,
	HOTEL_GUEST_EXTERNAL = 12,
	INTERNAL_DEPT_EXTERNAL = 13,
}
