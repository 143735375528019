
import { Options, Vue } from 'vue-class-component';
import TextInput from '@/common/components/form-ui/text-input.component.vue';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'InfoBox',
	components: { TextInput },
	emits: ['onEdit'],
})
export default class InfoBox extends Vue {
	@Prop({ default: true }) isActive!: boolean;
	@Prop({ default: true }) canEdit!: boolean;
}
