
import { iconLibrary, Icons } from '@/common/utilities/iconsLibrary.utility';
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'Checkbox',
})
export default class Checkbox extends Vue {
	@Prop() checked!: boolean;
	@Prop() enabledColor!: null | string; // custom color when checkbox is enabled

	Icons = Icons;
	iLib = iconLibrary;
}
